import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { authService } from './services/authService';
import Login from './login/Login';
import FormPage from './formPage/FormPage';
import AdminPage from './adminPage/AdminPage';
import MainPage from './mainPage/MainPage';
import RegisterPharmacy from './registerPharmacy/registerPharmacy';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const userData = await authService.getCurrentUser();
      if (userData) {
        handleLogin(userData);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (userData) => {
    setIsAuthenticated(true);
    setIsAdmin(userData.username.toLowerCase() === 'admin');
    setUsername(userData.username);
  };

  const handleLogout = async () => {
    await authService.logout();
    setIsAuthenticated(false);
    setIsAdmin(false);
    setUsername('');
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage handleLogin={handleLogin} />} />
        <Route
          path="/form"
          element={
            isAuthenticated && !isAdmin ? (
              <FormPage 
                handleLogout={handleLogout}
                username={username}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/admin"
          element={
            isAuthenticated && isAdmin ? (
              <AdminPage handleLogout={handleLogout} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="/register-pharmacy" element={<RegisterPharmacy />} />
      </Routes>
    </Router>
  );
}

export default App;
