import React, { useState } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import './registerPharmacy.css';

const RegisterPharmacy = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isFromAdmin = location.state?.fromAdmin;
    const reactAppUrl = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        nombre: '',
        password: '',
        confirmPassword: ''
    });

    if (!isFromAdmin) {
        return <Navigate to="/" replace />;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (formData.password !== formData.confirmPassword) {
            alert('Ambas contraseñas no coinciden');
            return;
        }

        if (formData.password.length < 10) {
            alert('La contraseña debe tener al menos 10 caracteres');
            return;
        }

        if (!/[A-Z]/.test(formData.password)) {
            alert('La contraseña debe contener al menos una letra mayúscula');
            return;
        }

        if (!/[a-z]/.test(formData.password)) {
            alert('La contraseña debe contener al menos una letra minúscula');
            return;
        }

        if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
            alert('La contraseña debe contener al menos un carácter especial');
            return;
        }

        try {
            const response = await fetch(`${reactAppUrl}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nombre: formData.nombre,
                    password: formData.password
                })
            });

            if (response.ok) {
                alert('Farmacia dada de alta');
                setFormData({ nombre: '', password: '', confirmPassword: '' });
            } else {
                alert('Error al registrar la farmacia');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error al conectar con el servidor');
        }
    };

    const handleVolverAdmin = () => {
        navigate('/admin');
    };

    return (
        <div className="register-container">
            <h2>Registro de Farmacia</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="nombre">Nombre:</label>
                    <input
                        type="text"
                        id="nombre"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Contraseña:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                    <div className="password-requirements">
                        <p>La contraseña debe cumplir los siguientes requisitos:</p>
                        <ul>
                            <li>Mínimo 10 caracteres</li>
                            <li>Al menos una letra mayúscula</li>
                            <li>Al menos una letra minúscula</li>
                            <li>Al menos un carácter especial (!@#$%^&*(),.?":{}|&lt;&gt;)</li>
                        </ul>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirmar Contraseña:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="button-group">
                    <button type="submit">Dar de Alta</button>
                    <button type="button" onClick={handleVolverAdmin}>Volver a Admin</button>
                </div>
            </form>
        </div>
    );
};

export default RegisterPharmacy;
