import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../login/Login';
import './MainPage.css';

function MainPage({ handleLogin }) {
  const navigate = useNavigate();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleLoginSuccess = (userData) => {
    handleLogin(userData);
    handleClosePopup();
    if (userData.username.toLowerCase() === 'admin') {
      navigate('/admin');
    } else {
      navigate('/form');
    }
  };

  const handleClosePopup = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowLoginPopup(false);
      setIsClosing(false);
    }, 300); // Debe coincidir con la duración de la animación
  };

  return (
    <div className="main-page">
      <div className="login-button">
        <button onClick={() => setShowLoginPopup(true)}>
          Inicio Sesión
        </button>
        {showLoginPopup && (
          <div className={`popup-overlay ${isClosing ? 'closing' : ''}`}>
            <div className="popup-content">
              <button 
                className="close-button" 
                onClick={handleClosePopup}
              >
                ×
              </button>
              <Login onLogin={handleLoginSuccess} />
            </div>
          </div>
        )}
      </div>

      <div className="content">
        <h1>Farmacia Rogelio Tenorio</h1>
        
        <div className="services" style={{"--order": 1}}>
          <h2>Servicios</h2>
          <ul>
            <li>Fórmulas magistrales</li>
            <li>Dermofarmacia</li>
          </ul>
        </div>

        <div className="schedule" style={{"--order": 2}}>
          <h2>Horario ininterrumpido</h2>
          <p>Lunes-viernes: 8:00h - 21:30h</p>
          <p>Sábados: 9:00h - 14:00h</p>
        </div>

        <div className="contact" style={{"--order": 3}}>
          <h2>Contacto</h2>
          <p>Tfno. Farmacia: <a href="tel:928243317">928 243 317</a></p>
          <p>Tfno. Laboratorio: <a href="tel:928293139">928 293 139</a></p>
          <p>Fax: 928 291 000</p>
          <p><a href="mailto:info@farmaciarogeliotenorio.es">info@farmaciarogeliotenorio.es</a></p>
        </div>

        <div className="address" style={{"--order": 4}}>
          <p>Calle de León y Castillo, 163</p>
          <p>35004 Las Palmas de Gran Canaria</p>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
