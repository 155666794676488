import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { getCognitoConfig } from '../config/cognitoConfig';

let userPool = null;

const initializeUserPool = async () => {
  if (!userPool) {
    const config = await getCognitoConfig();
    userPool = new CognitoUserPool(config);
  }
  return userPool;
};

const isDevelopment = process.env.NODE_ENV !== 'production';

export const authService = {
  login: async (username, password) => {
    if (isDevelopment) {
      if (!username || !password) {
        return Promise.reject(new Error('Username and password are required'));
      }
      localStorage.setItem('dev-user', username);
      return Promise.resolve({
        token: 'dev-token',
        isAdmin: username.toLowerCase() === 'admin',
        username: username
      });
    }
    
    await initializeUserPool();
    
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const userData = {
        Username: username,
        Pool: userPool
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const payload = result.getIdToken().decodePayload();
          const groups = payload['cognito:groups'] || [];
          const isAdmin = groups.includes('admin');
          
          resolve({
            token: result.getIdToken().getJwtToken(),
            isAdmin: isAdmin,
            username: username
          });
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes) => {
          cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: (result) => {
              const payload = result.getIdToken().decodePayload();
              const groups = payload['cognito:groups'] || [];
              const isAdmin = groups.includes('admin');
              
              resolve({
                token: result.getIdToken().getJwtToken(),
                isAdmin: isAdmin,
                username: username
              });
            },
            onFailure: (err) => {
              reject(err);
            }
          });
        }
      });
    });
  },

  getCurrentUser: async () => {
    if (isDevelopment) {
      const storedUser = localStorage.getItem('dev-user');
      if (!storedUser) {
        return Promise.resolve(null);
      }
      
      return Promise.resolve({
        token: 'dev-token',
        isAdmin: storedUser.toLowerCase() === 'admin',
        username: storedUser
      });
    }
    
    await initializeUserPool();
    
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      
      if (!cognitoUser) {
        resolve(null);
        return;
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        if (session.isValid()) {
          const payload = session.getIdToken().decodePayload();
          const groups = payload['cognito:groups'] || [];
          const isAdmin = groups.includes('admin');
          
          resolve({
            token: session.getIdToken().getJwtToken(),
            isAdmin: isAdmin,
            username: cognitoUser.getUsername()
          });
        } else {
          resolve(null);
        }
      });
    });
  },
  
  logout: async () => {
    if (isDevelopment) {
      localStorage.removeItem('dev-user');
      return Promise.resolve();
    }
    
    await initializeUserPool();
    
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
  }
};
