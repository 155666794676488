/**
 * It fetches the authentication configuration
 * @returns {Object} - The authentication configuration
 */
async function fetchCognitoConfig() {
  try {
    const url = process.env.REACT_APP_API_URL;
    const response = await fetch(`${url}/auth`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch authentication configuration');
    }

    const data = await response.json();
    return {
      UserPoolId: data.user_pool,
      ClientId: data.client_id,
    };
  } catch (error) {
    console.error('Error fetching authentication configuration:', error);
    return null;
  }
}

/**
 * It gets the authentication configuration
 * @returns {Object} - The authentication configuration
 */
export const getCognitoConfig = async () => {
  const config = await fetchCognitoConfig();
  if (config) {
    return config;
  } else {
    throw new Error('Failed to fetch authentication configuration');
  }
};
