import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export async function createForm(dataArray) {
  const pdfDoc = await PDFDocument.create();
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  console.log(dataArray)
  for (const data of dataArray) {
    const page = pdfDoc.addPage([550, 750]);

    const drawText = (text, x, y, size = 12) => {
      page.drawText(String(text), { x, y, size, font })
    }

    drawText(data.title, 50, 700, 20)
    page.drawText(data.solicitud_farmaceutico_text, {
      x: 50,
      y: 680,
      size: 12,
      font,
      color: rgb(0, 0, 0),
      maxWidth: 450,
      lineHeight: 15
    })

    drawText(data.solicita_header, 50, 580)

    page.drawText(data.solicita_content, {
      x: 50,
      y: 560,
      size: 12,
      font,
      color: rgb(0, 0, 0),
      maxWidth: 450,
      lineHeight: 15
    });

    drawText(data.paciente_text, 50, 480)
    drawText(data.medico_text, 50, 460)

    // add products table
    let yPosition = 430;
    const tableWidth = 450;
    const colWidth = tableWidth / 3;
    const startX = (page.getWidth() - tableWidth) / 2; // Centrar la tabla
    const rowHeight = 25;

    // Encabezados de columnas
    drawText('Producto', startX + 10, yPosition, 12);
    drawText('Cantidad', startX + colWidth + 10, yPosition, 12);
    drawText('Unidad', startX + (colWidth * 2) + 10, yPosition, 12);

    yPosition -= 15;

    // Líneas verticales de la tabla
    data.productos.forEach((producto, index) => {
      const currentY = yPosition - (index * rowHeight);
      
      // Dibuja el contenido
      drawText(producto.producto, startX + 10, currentY, 12);
      drawText(producto.cantidad.toString(), startX + colWidth + 10, currentY, 12);
      drawText(producto.unidad, startX + (colWidth * 2) + 10, currentY, 12);
    });

    // Calcula la nueva posición Y después de la tabla
    yPosition = yPosition - (data.productos.length * rowHeight) - 40;

    // Asegura un espacio mínimo para la fecha y firmas
    const minYPosition = 100;
    yPosition = Math.max(yPosition, minYPosition);

    // add date
    drawText(data.date_text, 50, yPosition);

    // add signatures (30 unidades más abajo de la fecha)
    const signaturesY = yPosition - 30;
    drawText(data.farmacia_solicitante, 50, signaturesY);
    drawText(data.farmaceutico_elaborador, 370, signaturesY);
  }

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
}
