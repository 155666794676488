import React, { useState, useEffect } from 'react';
import './AdminPage.css';

import { useNavigate } from 'react-router-dom';
import { createForm } from '../pdf/PdfCreation';


function AdminPage({ handleLogout }) {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [downloadStatus, setDownloadStatus] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [downloadedItems, setDownloadedItems] = useState(0);
  const [pendingItems, setPendingItems] = useState(0);
  const [viewingDownloaded, setViewingDownloaded] = useState(false);
  const [viewingPending, setViewingPending] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);

  const reactAppUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await fetch(`${reactAppUrl}/admin-preview?date=${encodeURIComponent(selectedDate)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Error en la respuesta del servidor');
        }

        const data = await response.json();
        const stats = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;

        setTotalItems(stats.total || 0);
        setDownloadedItems(stats.downloaded || 0);
        setPendingItems(stats.pending || 0);
      } catch (error) {
        console.error('Error al obtener la vista previa:', error);
        setDownloadStatus('Error al obtener la vista previa. Por favor, intente de nuevo.');
        setTimeout(() => setDownloadStatus(''), 5000);
      }
    };

    fetchInitialData();
  }, []);

  /**
   * It handles the date change
   * @param {Event} e - The event
   */
  const handleDateChange = async (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);

    try {
      const response = await fetch(`${reactAppUrl}/admin-preview?date=${encodeURIComponent(newDate)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Error en la respuesta del servidor');
      }

      const data = await response.json();
      const stats = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;

      setTotalItems(stats.total || 0);
      setDownloadedItems(stats.downloaded || 0);
      setPendingItems(stats.pending || 0);
    } catch (error) {
      console.error('Error al obtener la vista previa:', error);
      setDownloadStatus('Error al obtener la vista previa. Por favor, intente de nuevo.');
      setTimeout(() => setDownloadStatus(''), 5000);
    }
  };

  /**
   * It handles the download
   */
  const handleDownload = async (type = 'all') => {
    if (!selectedDate) {
      setDownloadStatus('Por favor, seleccione una fecha antes de descargar.');
      setTimeout(() => setDownloadStatus(''), 5000);
      return;
    }

    if (type === 'downloaded' && downloadedItems === 0) {
      setDownloadStatus('No hay elementos ya descargados para descargar.');
      setTimeout(() => setDownloadStatus(''), 5000);
      return;
    }

    if (type === 'pending' && pendingItems === 0) {
      setDownloadStatus('No hay elementos pendientes para descargar.');
      setTimeout(() => setDownloadStatus(''), 5000);
      return;
    }

    if (type === 'all' && totalItems === 0) {
      setDownloadStatus('No hay elementos para descargar.');
      setTimeout(() => setDownloadStatus(''), 5000);
      return;
    }

    setDownloadStatus('Descargando...');

    // Map of endpoints based on type
    const endpoints = {
      all: '/admin',
      downloaded: '/download-downloaded-items',
      pending: '/download-undownloaded-items'
    };

    try {
      const response = await fetch(`${reactAppUrl}${endpoints[type]}?date=${encodeURIComponent(selectedDate)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error en la respuesta del servidor');
      }

      const data = await response.json();

      // Parse the body if it's a string
      const items = typeof data.body === 'string' ? JSON.parse(data.body).items : data.body.items;
      const pdfBytes = await createForm(items);
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      
      const url = URL.createObjectURL(blob);
      
      // Create a temporary link and click on it to download the PDF
      const link = document.createElement('a');
      link.href = url;
      link.download = `datos_${selectedDate}.pdf`;
      link.click();
      
      // Release the URL of the object
      URL.revokeObjectURL(url);

      // Actualizar el estado para indicar que la descarga se completó
      setDownloadStatus('Descarga completada');
      setTimeout(() => setDownloadStatus(''), 5000);

    } catch (error) {
      console.error('Error:', error);
      setDownloadStatus('Error en la descarga. Por favor, intente de nuevo.');
      setTimeout(() => setDownloadStatus(''), 5000);
    }
  };

  const handleLogoutClick = async () => {
    try {
      await handleLogout();
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      setDownloadStatus('Error al cerrar sesión. Por favor, intente de nuevo.');
      setTimeout(() => setDownloadStatus(''), 5000);
    }
  };

  const fetchItems = async (type) => {
    try {
      if (type === 'downloaded' && downloadedItems === 0) {
        setDownloadStatus('No hay elementos descargados para visualizar.');
        setTimeout(() => setDownloadStatus(''), 5000);
        setPdfPreviewUrl(null);
        setItemsList([]);
        return;
      }
      
      if (type === 'pending' && pendingItems === 0) {
        setDownloadStatus('No hay elementos pendientes para visualizar.');
        setTimeout(() => setDownloadStatus(''), 5000);
        setPdfPreviewUrl(null);
        setItemsList([]);
        return;
      }

      const endpoint = type === 'downloaded' 
        ? '/view-downloaded-items'
        : '/view-undownloaded-items';

      const response = await fetch(`${reactAppUrl}${endpoint}?date=${encodeURIComponent(selectedDate)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) throw new Error('Error en la respuesta del servidor');
      const data = await response.json();
      const items = typeof data.body === 'string' ? JSON.parse(data.body).items : data.body.items;
      
      if (!items || items.length === 0) {
        setPdfPreviewUrl(null);
        setItemsList([]);
        return;
      }

      setItemsList(items);
      const pdfBytes = await createForm(items);
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setPdfPreviewUrl(url);
    } catch (error) {
      console.error('Error:', error);
      setDownloadStatus('Error en la consulta. Por favor, intente de nuevo.');
      setTimeout(() => setDownloadStatus(''), 5000);
    }
  };

  const handleRegisterPharmacy = () => {
    navigate('/register-pharmacy', { state: { fromAdmin: true } });
  };

  return (
    <div className="admin-page">
      <div className="header-section">
        <div className="header-content">
          <h1>Página de Administración</h1>
          <button onClick={handleLogoutClick} className="logout-button">
            Cerrar sesión
          </button>
          <button onClick={handleRegisterPharmacy} className="register-button">
            Registrar Nueva Farmacia
          </button>
        </div>
      </div>

      <div className="main-content">
        <div className="date-section">
          <h2>Fórmulas para día</h2>
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="date-input"
          />
        </div>

        <div className="stats-section">
          <div className="stat-row">
            <span className="stat-label">Número total:</span>
            <span className="stat-value">{totalItems}</span>
            <button onClick={() => handleDownload('all')} className="action-button">
              Descargar todas
            </button>
          </div>

          <div className="stat-row">
            <span className="stat-label">Descargadas:</span>
            <span className="stat-value">{downloadedItems}</span>
            <button onClick={() => handleDownload('downloaded')} className="action-button">
              Descargar ya descargadas
            </button>
          </div>

          <div className="stat-row">
            <span className="stat-label">No descargadas:</span>
            <span className="stat-value">{pendingItems}</span>
            <button onClick={() => handleDownload('pending')} className="action-button">
              Descargar no descargadas
            </button>
          </div>
        </div>

        <div className="preview-section">
          <div className="preview-controls">
            <button 
              onClick={() => {
                setViewingDownloaded(true);
                setViewingPending(false);
                fetchItems('downloaded');
              }}
              className="view-button"
            >
              Ver elementos descargados
            </button>
            <button 
              onClick={() => {
                setViewingDownloaded(false);
                setViewingPending(true);
                fetchItems('pending');
              }}
              className="view-button"
            >
              Ver elementos no descargados
            </button>
          </div>

          <p className="status-message">{downloadStatus}</p>
          
          <div className="preview-box">
            {pdfPreviewUrl && (
              <iframe
                src={pdfPreviewUrl}
                width="100%"
                height="600px"
                title="PDF Preview"
                style={{ border: 'none' }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
