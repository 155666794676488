import React, { useState, useEffect } from 'react';
import './FormPage.css';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { createForm } from '../pdf/PdfCreation';

function FormPage({ handleLogout, username }) {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [farmaceuticoLoaded, setFarmaceuticoLoaded] = useState(false);
  const [farmaceuticoData, setFarmaceuticoData] = useState({
    nombre: '',
    direccion: '',
    dni: '',
    correo: '',
    telefono: ''
  });
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const productosTable = document.getElementById('productosTable').getElementsByTagName('tbody')[0];
    const addRowButton = document.getElementById('addRow');
    const deleteRowButton = document.getElementById('deleteRow');

    function addTableRow() {
      const newRow = productosTable.insertRow();
      const columns = ['producto', 'cantidad', 'unidad'];
      columns.forEach(column => {
        const cell = newRow.insertCell();
        const input = document.createElement('input');
        input.type = 'text';
        input.name = column;
        input.placeholder = column.charAt(0).toUpperCase() + column.slice(1);
        cell.appendChild(input);
      });
    }

    function deleteTableRow() {
      if (productosTable.rows.length > 1) {
        productosTable.deleteRow(productosTable.rows.length - 1);
      }
    }

    addRowButton.addEventListener('click', addTableRow);
    deleteRowButton.addEventListener('click', deleteTableRow);

    // Agregar la primera fila por defecto
    addTableRow();

    // Limpieza de event listeners
    return () => {
      addRowButton.removeEventListener('click', addTableRow);
      deleteRowButton.removeEventListener('click', deleteTableRow);
    };
  }, []);

  useEffect(() => {
    const fetchFarmaceuticoData = async () => {
      try {
        console.log("Iniciando fetch de datos del farmacéutico");
        console.log("Username:", username);
        
        const response = await fetch(`${url}/populate?farmaceutico-username=${username.toLowerCase()}`, {
          headers: {
            'Content-Type': 'application/json',
            'Origin': window.location.origin,
          },
        });
        
        const responseData = await response.json();
        // Parseamos el body que viene como string
        const data = JSON.parse(responseData.body);
        console.log("Datos parseados:", data);
        
        if (data && Object.keys(data).length > 0) {
          setFarmaceuticoData({
            nombre: data.nombre || '',
            direccion: data.direccion || '',
            dni: data.dni || '',
            correo: data.correo || '',
            telefono: data.telefono || ''
          });
          setFarmaceuticoLoaded(true);
        }
      } catch (error) {
        console.error('Error al obtener datos del farmacéutico:', error);
      }
    };

    if (username) {
      fetchFarmaceuticoData();
    }
  }, [username]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const productos = Array.from(document.getElementById('productosTable').getElementsByTagName('tbody')[0].rows)
      .map(row => ({
        producto: row.cells[0].getElementsByTagName('input')[0].value.trim(),
        cantidad: row.cells[1].getElementsByTagName('input')[0].value.trim(),
        unidad: row.cells[2].getElementsByTagName('input')[0].value.trim(),
      }))
      .filter(producto => producto.producto || producto.cantidad || producto.unidad);

    // Validar que todas las celdas de cada fila de productos estén completas
    const incompleteRow = productos.find(producto => 
      !producto.producto || !producto.cantidad || !producto.unidad
    );

    if (incompleteRow) {
      setError('Por favor, complete todos los campos de cada composición antes de continuar.');
      return;
    }

    if (!farmaceuticoLoaded) {
      try {
        const farmaceuticoData = {
          username: username.toLowerCase(),
          nombre: document.getElementById('nombre').value,
          direccion: document.getElementById('direccion').value,
          dni: document.getElementById('dni').value,
          correo: document.getElementById('correo').value,
          telefono: document.getElementById('telefono').value,
        };
        // Guardar datos del farmacéutico si es la primera vez
        await fetch(`${url}/create-pharmacist`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Origin': window.location.origin,
          },
          body: JSON.stringify(farmaceuticoData),
        });
      } catch (error) {
        console.error('Error al guardar datos del farmacéutico:', error);
        setError('Error al guardar los datos del farmacéutico');
        return;
      }
    }

    const data = {
      medico: document.getElementById('medico').value,
      paciente: document.getElementById('paciente').value,
      productos: productos,
      farmaceutico: farmaceuticoData,
      id: uuidv4()
    };
    
    setFormData(data);
    setShowPopup(true);
  };

  const handleConfirm = async () => {
    try {      
      const response = await fetch(`${url}/cliente`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': window.location.origin,
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        // Limpiar campos
        document.getElementById('medico').value = '';
        document.getElementById('paciente').value = '';
        
        // Limpiar tabla de productos
        const productosTable = document.getElementById('productosTable').getElementsByTagName('tbody')[0];
        while (productosTable.rows.length > 0) {
          productosTable.deleteRow(0);
        }
        
        // Agregar una fila vacía nueva
        const newRow = productosTable.insertRow();
        ['producto', 'cantidad', 'unidad'].forEach(column => {
          const cell = newRow.insertCell();
          const input = document.createElement('input');
          input.type = 'text';
          input.name = column;
          input.placeholder = column.charAt(0).toUpperCase() + column.slice(1);
          cell.appendChild(input);
        });

        setShowPopup(false);
        setShowDownloadPopup(true); // Mostrar el popup de descarga
      } else {
        throw new Error('Error al enviar la información');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error al enviar la información. Por favor, intente nuevamente.');
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(`${url}/download-item-by-id?id=${formData.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Origin': window.location.origin,
        }
      });

      if (!response.ok) {
        throw new Error('Error al descargar el PDF');
      }

      const pdfData = await response.json();

      const pdfItems = typeof pdfData.body === 'string' ? JSON.parse(pdfData.body).items : pdfData.body.items;
      const pdfBytes = await createForm(pdfItems);
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const downloadUrl = URL.createObjectURL(pdfBlob);
      const pdfLink = document.createElement('a');
      pdfLink.href = downloadUrl;
      pdfLink.download = `formula_magistral_${new Date().toISOString().split('T')[0]}.pdf`;
      pdfLink.click();
      window.URL.revokeObjectURL(downloadUrl);
  
      setShowDownloadPopup(false);
    } catch (error) {
      console.error('Error al descargar el PDF:', error);
      alert('Error al descargar el PDF. Por favor, intente nuevamente.');
    }
  };

  const handleSkipDownload = () => {
    setShowDownloadPopup(false);
  };

  const handleModify = () => {
    setShowPopup(false);
  };

  const handleLogoutClick = async () => {
    try {
      await handleLogout();
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <h1>Solicitud Fórmula Magistral</h1>
        <button 
          onClick={handleLogoutClick}
          className="logout-button"
        >
          Cerrar sesión
        </button>
        
        <div className="form-group">
          <label htmlFor="medico">Médico prescriptor:</label>
          <input type="text" id="medico" name="medico" required />
        </div>
        
        <div className="form-group">
          <label htmlFor="paciente">Paciente:</label>
          <input type="text" id="paciente" name="paciente" required />
        </div>
        
        <h2>Descripción</h2>
        <table id="productosTable">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Unidad</th>
            </tr>
          </thead>
          <tbody>
            {/* Las filas de productos se agregarán dinámicamente aquí */}
          </tbody>
        </table>
        <button type="button" id="addRow">Añadir Composición</button>
        <button type="button" id="deleteRow">Eliminar Composición</button>
        
        <h2>El farmacéutico solicitante</h2>
        <div className="form-group">
          <label htmlFor="nombre">Nombre:</label>
          <input 
            type="text" 
            id="nombre" 
            name="nombre" 
            value={farmaceuticoData.nombre}
            onChange={(e) => setFarmaceuticoData(prev => ({...prev, nombre: e.target.value}))}
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="direccion">Dirección:</label>
          <input 
            type="text" 
            id="direccion" 
            name="direccion" 
            value={farmaceuticoData.direccion}
            onChange={(e) => setFarmaceuticoData(prev => ({...prev, direccion: e.target.value}))}
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="dni">DNI:</label>
          <input 
            type="text" 
            id="dni" 
            name="dni" 
            value={farmaceuticoData.dni}
            onChange={(e) => setFarmaceuticoData(prev => ({...prev, dni: e.target.value}))}
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="correo">Correo:</label>
          <input 
            type="email" 
            id="correo" 
            name="correo" 
            value={farmaceuticoData.correo}
            onChange={(e) => setFarmaceuticoData(prev => ({...prev, correo: e.target.value}))}
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefono">Teléfono:</label>
          <input 
            type="tel" 
            id="telefono" 
            name="telefono" 
            value={farmaceuticoData.telefono}
            onChange={(e) => setFarmaceuticoData(prev => ({...prev, telefono: e.target.value}))}
            required 
          />
        </div>
        
        <button type="submit" className="submit-btn">Enviar</button>
        {error && <p className="error-message">{error}</p>}
      </form>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>¿Seguro que quieres enviar esta información?</h2>
            <div className="summary">
              <p><strong>Médico:</strong> {formData.medico}</p>
              <p><strong>Paciente:</strong> {formData.paciente}</p>
              <h3>Composición:</h3>
              <ul>
                {formData.productos.map((producto, index) => (
                  <li key={index}>{producto.producto}: {producto.cantidad} {producto.unidad}</li>
                ))}
              </ul>
              <h3>Farmacéutico:</h3>
              <p><strong>Nombre:</strong> {formData.farmaceutico.nombre}</p>
              <p><strong>Dirección:</strong> {formData.farmaceutico.direccion}</p>
              <p><strong>DNI:</strong> {formData.farmaceutico.dni}</p>
              <p><strong>Correo:</strong> {formData.farmaceutico.correo}</p>
              <p><strong>Teléfono:</strong> {formData.farmaceutico.telefono}</p>
            </div>
            <div className="popup-buttons">
              <button onClick={handleConfirm}>Acepto enviar los datos a la Farmacia Rogelio Tenorio Hdez</button>
              <button onClick={handleModify}>Modificar</button>
            </div>
          </div>
        </div>
      )}

      {showDownloadPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>¿Quieres descargar la solicitud de fórmula magistral enviada a Farmacia Rogelio Tenorio?</h2>
            <div className="popup-buttons">
              <button onClick={handleDownload}>Descargar solicitud de fórmula magistral</button>
              <button onClick={handleSkipDownload}>No, gracias</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormPage;
